$(document).ready(function() {

	// const showFirstTierMenuItems = $("#menu-tier-primary").hasClass('show-first-tier') ? true : false;

	// const menuTierPrimaryCol = $("#menu-tier-primary-col");
	// const menuTierPrimary = $("#menu-tier-primary");

	// const menuTierSecondaryCol = $("#menu-tier-secondary-col");
	// const menuTierSecondary = $("#menu-tier-secondary");

	// const menuWrapper = $("#menu-wrapper");

	// const toggleMenuClass = function() {
	// 	$("body").toggleClass('nav-open');
	// 	$(".site-nav-hamburger").toggleClass('is-active');
	// 	menuWrapper.toggleClass('active');
	// 	menuTierPrimaryCol.removeClass('active');
	// 	menuTierSecondaryCol.removeClass('active');
	// 	$(".secondary-tier-menu").removeClass('active');
	// }

	// $("body").on('click', '.menu-trigger', function() {
	// 	const isDesktop = $('html').hasClass('desktop');
	// 	if (isDesktop) {
	// 		toggleMenuClass();
	// 	}
	// });

	// // add menu item count and sub active icon
	// $('ul.menu li.menu-item-has-children', menuTierPrimary).each(function(i, el) {
	// 	const menuItemCount = $("> ul.child-menu > li", $(this)).length;
	// 	const arrowRight = $("#desktop-menu").attr('data-arrow-right');
	// 	$("> a.dropdown-item", $(this)).append('<span class="activate-sub-menu"><img src="' + arrowRight + '" class="menu-sub-active"></span>');
	// 	$("> a.dropdown-item .link-text-wrapper", $(this)).append('<sup class="menu-item-count">' + ('0' + menuItemCount).slice(-2) + '</sup>');
	// })

	// let childMenuCount = 0;
	// $("body").on("click", ".activate-sub-menu", function(e) {
	// 	e.preventDefault();

	// 	const isPrimaryActivation = $(this).closest('#menu-tier-primary').length ? true : false;
	// 	if (isPrimaryActivation) {
	// 		const currentPrimaryMenu = $("#menu-tier-secondary #child-menu-1");
	// 		const hasCurrentPrimaryMenu = currentPrimaryMenu.length > 0 ? true : false;
	// 		childMenuCount = 0;
	// 		if (hasCurrentPrimaryMenu) {
	// 			currentPrimaryMenu.attr('data-id', 0).attr('id', 'child-menu-0').removeClass('active');
	// 			setTimeout(function() {
	// 				$(".secondary-tier-menu").each(function() {
	// 					console.log($(this).attr('data-id'));
	// 					if ($(this).attr('id') !== 'child-menu-1') {
	// 						$(this).remove();
	// 					}
	// 				})
	// 			}, 1000);
	// 		}
	// 	}

	// 	childMenuCount++;
	// 	menuTierPrimaryCol.addClass('active');
	// 	menuTierSecondaryCol.addClass('active');
	// 	const childMenu = '<ul data-id="' + childMenuCount + '" id="child-menu-' + childMenuCount + '" class="menu secondary-tier-menu">' + $(this).closest('.menu-item').prop('outerHTML') + '</ul>';
	// 	menuTierSecondary.append(childMenu);
	// 	setTimeout(function() {
	// 		const arrowLeft = $("#desktop-menu").attr('data-arrow-left');
	// 		$(".secondary-tier-menu").removeClass('active');
	// 		$(".secondary-tier-menu > li > a > .activate-sub-menu").removeClass('activate-sub-menu').addClass('previous-sub-menu').attr('data-current', childMenuCount).attr('data-previous', childMenuCount - 1);
	// 		$(".secondary-tier-menu > li > a > .previous-sub-menu img").attr("src", arrowLeft);
	// 		$("#child-menu-" + childMenuCount).addClass('active');
	// 	}, 100);
	// });

	// $("body").on("click", ".previous-sub-menu", function(e) {
	// 	e.preventDefault();
	// 	const currentMenu = $(this).attr('data-current');
	// 	const previousMenu = $(this).attr('data-previous');
	// 	if (previousMenu == 0) {
	// 		menuTierPrimaryCol.removeClass('active');
	// 		menuTierSecondaryCol.removeClass('active');
	// 		$(".secondary-tier-menu").removeClass('active');
	// 	} else {
	// 		setTimeout(function() {
	// 			$("#child-menu-" + currentMenu).removeClass('active');
	// 			$("#child-menu-" + previousMenu).addClass('active');
	// 		}, 100);
	// 	}

	// 	setTimeout(function() {
	// 		$("#child-menu-" + currentMenu).remove();
	// 		childMenuCount--;
	// 	}, 500);
	// });


});
