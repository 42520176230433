/* Video Form Popup Data Component JS File */
$(document).ready(function() {
	window.videoCompleteFormPopup = function videoCompleteFormPopup(data) {
	const form = typeof data.form !== "undefined" ? data.form : false;
	const title = typeof data.formTitle !== "undefined" ? data.formTitle : false;
	const content = typeof data.formContent !== "undefined" ? data.formContent : false;
	const video = typeof data.video !== "undefined" ? data.video : false;
	if (form) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: 'video-complete-form-popup fade-in',
			tLoading: coreData.loader,
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "video_complete_form_popup",
						form: form,
						title: title,
						content: content,
						video: video
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			},
			callbacks: {
				ajaxContentAdded: function() {

				}
			}
		});
	}
}
});
