/* Info Box Block JS File */
$(document).ready(function() {

});



$(window).on('resize rotate load', function() {
	if ($('.info-box-wrapper').length > 0) {
		if ($(window).width() < 992) {
			$('.info-box-wrapper #col-info .section-info-box').each(function() {
				const change = $(this).attr('data-change');
				console.log(change);
				console.log(".info-box-wrapper " + change + " .section-header");
				$(this).find('.section-title').appendTo(".info-box-wrapper " + change + " .section-header");
				$(this).find('.section-content').appendTo(".info-box-wrapper " + change + " .section-body");
				$(this).find('.section-button').appendTo(".info-box-wrapper " + change + " .section-body");
			});
		} else {
			$('.info-box-wrapper .info-box-accordion .card').each(function() {
				const change = $(this).attr('data-change');
				console.log(change);
				console.log(".info-box-wrapper " + change + " .section-header");
				$(this).find('.section-title').appendTo(".info-box-wrapper " + change);
				$(this).find('.section-content').appendTo(".info-box-wrapper " + change);
				$(this).find('.section-button').appendTo(".info-box-wrapper " + change);
			});
		}
	}
});
