import '../scss/core.scss';

import Cookies from 'js-cookie';
window.Cookies = Cookies;

import LazyLoad from 'vanilla-lazyload';
window.lazyLoadInstance = new LazyLoad({
	elements_selector: ".lazy",
	threshold: 1000
});

import Plyr from 'plyr';
window.Plyr = Plyr;

import magnificPopup from 'magnific-popup';
window.magnificPopup = magnificPopup;

import slick from 'slick-carousel';
window.slick = slick;

let ww = $(window).width();
$(window).on('load rotate resize', function() {
	ww = $(window).width();
});

// on update of location reload browser
window.locationUpdated = function() {
	location.reload();
};

$(window).on('load', function() {
	$(".defered-styles").attr('rel', 'stylesheet');
});


import './_modernizr';
import MobileDetect from 'mobile-detect';
window.md = new MobileDetect(window.navigator.userAgent);

function isIpadOS() {
  return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
}

window.getDeviceType = function getDeviceType() {
    let deviceType = false;
    if (!(!!md.mobile() || !!md.phone() || !!md.tablet())) {
        if (isIpadOS()) {
			deviceType = 'tablet';
		} else {
			deviceType = 'desktop';
		}
    } else if (!!md.phone()) {
        deviceType = 'mobile';
    } else if (!!md.tablet()) {
        deviceType = 'tablet';
    }

    return deviceType;
}
window.deviceType = getDeviceType();

$(window).on('load resize rotate', function() {
    $("html").addClass(getDeviceType());
    if ($(".sp-block").length > 0) {
        $.each($(".sp-block > div"), function() {
            $(this).addClass(getDeviceType());
        })
    }
});

function css_browser_selector(u) {
	let ua = u.toLowerCase(),
		is = function(t) {
			return ua.indexOf(t) > -1
		}, g = 'gecko',
		w = 'webkit',
		s = 'safari',
		o = 'opera',
		m = 'mobile',
		h = document.documentElement,
		b = [(!(/opera|webtv/i.test(ua)) && /msie\s(\d)/.test(ua)) ? ('ie ie' + RegExp.$1) : is('firefox/2') ? g + ' ff2' : is('firefox/3.5') ? g + ' ff3 ff3_5' : is('firefox/3.6') ? g + ' ff3 ff3_6' : is('firefox/3') ? g + ' ff3' : is('gecko/') ? g : is('opera') ? o + (/version\/(\d+)/.test(ua) ? ' ' + o + RegExp.$1 : (/opera(\s|\/)(\d+)/.test(ua) ? ' ' + o + RegExp.$2 : '')) : is('konqueror') ? 'konqueror' : is('blackberry') ? m + ' blackberry' : is('android') ? m + ' android' : is('chrome') ? w + ' chrome' : is('iron') ? w + ' iron' : is('applewebkit/') ? w + ' ' + s + (/version\/(\d+)/.test(ua) ? ' ' + s + RegExp.$1 : '') : is('mozilla/') ? g : '', is('j2me') ? m + ' j2me' : is('iphone') ? m + ' iphone' : is('ipod') ? m + ' ipod' : is('ipad') ? m + ' ipad' : is('mac') ? 'mac' : is('darwin') ? 'mac' : is('webtv') ? 'webtv' : is('win') ? 'win' + (is('windows nt 6.0') ? ' vista' : '') : is('freebsd') ? 'freebsd' : (is('x11') || is('linux')) ? 'linux' : '', 'js'];
	const c = b.join(' ');
	h.className += ' ' + c;
	var ie = ua.search(/(MSIE|Trident)/);
	if (ie > -1) {
		$("html").addClass('browse-happy');
	}
	$("html").addClass(c);
	return c;
}
const browser = css_browser_selector(navigator.userAgent);

import AOS from "aos";
window.AOS = AOS;
$(document).ready(function() {
	if ($("*[data-aos]").length) {
		AOS.init({
			easing: 'ease-in-out-back',
			duration: 1000,
			offset: 100,
			delay: 0
		});
	}
})

import notify from 'notifyjs-browser';

// Gravity Form Javascript
import './_forms';

// Auto import js from component folder
import './../../library/components/bannerBlog/bannerBlog.js'; import './../../library/components/bannerCase/bannerCase.js'; import './../../library/components/bannerContent/bannerContent.js'; import './../../library/components/bannerHome/bannerHome.js'; import './../../library/components/bannerVideoHub/bannerVideoHub.js'; import './../../library/components/bannerVideoSingle/bannerVideoSingle.js'; import './../../library/components/bannerVideoSlider/bannerVideoSlider.js'; import './../../library/components/button/button.js'; import './../../library/components/informationBar/informationBar.js'; import './../../library/components/relatedVideosComponent/relatedVideosComponent.js'; import './../../library/components/singleVideo/singleVideo.js'; import './../../library/components/videoFormPopupData/videoFormPopupData.js'; import './../../library/components/videoSlider/videoSlider.js';
// Auto import js from blocks folder
import './../../library/blocks/accordion/accordion.js'; import './../../library/blocks/blogsSlider/blogsSlider.js'; import './../../library/blocks/casesSlider/casesSlider.js'; import './../../library/blocks/contactDetails/contactDetails.js'; import './../../library/blocks/contentAndImage/contentAndImage.js'; import './../../library/blocks/contentAndImageAlt/contentAndImageAlt.js'; import './../../library/blocks/contentBlock/contentBlock.js'; import './../../library/blocks/contentBlockAlt/contentBlockAlt.js'; import './../../library/blocks/disclaimer/disclaimer.js'; import './../../library/blocks/formCTA/formCTA.js'; import './../../library/blocks/gravityForm/gravityForm.js'; import './../../library/blocks/hoverBoxes/hoverBoxes.js'; import './../../library/blocks/infoBox/infoBox.js'; import './../../library/blocks/instagramFeed/instagramFeed.js'; import './../../library/blocks/introContent/introContent.js'; import './../../library/blocks/mapAndContactDetails/mapAndContactDetails.js'; import './../../library/blocks/mapAndContactInfo/mapAndContactInfo.js'; import './../../library/blocks/newsletterSubscription/newsletterSubscription.js'; import './../../library/blocks/pageIntro/pageIntro.js'; import './../../library/blocks/pageList/pageList.js'; import './../../library/blocks/pageMenu/pageMenu.js'; import './../../library/blocks/parentAndChildLinks/parentAndChildLinks.js'; import './../../library/blocks/partners/partners.js'; import './../../library/blocks/postGrid/postGrid.js'; import './../../library/blocks/quote/quote.js'; import './../../library/blocks/quoteAlt/quoteAlt.js'; import './../../library/blocks/sidebar/sidebar.js'; import './../../library/blocks/tabbedContent/tabbedContent.js'; import './../../library/blocks/teamMembers/teamMembers.js'; import './../../library/blocks/video/video.js'; import './../../library/blocks/videoGrid/videoGrid.js';
// Auto import js from widgets folder
import './../../library/widgets/gravityForm/gravityForm.js'; import './../../library/widgets/guideWidget/guideWidget.js'; import './../../library/widgets/navigationMenu/navigationMenu.js';
// Auto import js from cpt folder
import './../../library/cpt/guide/guide.js'; import './../../library/cpt/videoHub/videoHub.js';
// Auto import js from templates folder
import './../../library/templates/breadcrumbs/breadcrumbs.js'; import './../../library/templates/client/contactUs/contactAccordion/contactAccordion.js'; import './../../library/templates/client/location/googleMap/googleMap.js'; import './../../library/templates/client/location/map/map.js'; import './../../library/templates/content/blog/blogPost/blogPost.js'; import './../../library/templates/footer/footer.js'; import './../../library/templates/form/form.js'; import './../../library/templates/guide/guide.js'; import './../../library/templates/header/desktopHeader/desktopHeader.js'; import './../../library/templates/header/header.js'; import './../../library/templates/header/mobileHeader/mobileHeader.js'; import './../../library/templates/icon/icon.js'; import './../../library/templates/menu/desktopMenu/desktopMenu.js'; import './../../library/templates/menu/mobileMenu/mobileMenu.js'; import './../../library/templates/scrollSnap/scrollSnap.js'; import './../../library/templates/share/share.js'; import './../../library/templates/titleSeo/titleSeo.js';

window.matchHeight = function(element) {
	let maxHeight = 0;
	let match;
	match = $(element);
	match.height('auto');
	match.each(function() {
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	match.height(maxHeight);
}

// Get the users coordinates
window.getLocation = function(callback) {
	if (coreData.spOptions.location_update !== 'disabled') {
		if ("geolocation" in navigator) {
			function position(position) {
				const userCoords = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				};
				if (typeof callback !== 'undefined') {
					callback(userCoords);
				}
			}
			function error(error) {
				console.log(error);
			}
			const options = {}
			if (coreData.spOptions.location_update == 'change') {
				navigator.geolocation.watchPosition(position, error, options);
			} else if (coreData.spOptions.location_update == 'reload') {
				navigator.geolocation.getCurrentPosition(position, error, options);
			}
		}
	}
};

window.setLocation = function(location, callback) {
	// if the client data has more than 1 location
	if (Object.keys(coreData.clientData).length > 1) {
		// initiate the getLocation function
		getLocation(function(userCoords) {
			const currentLocation = Cookies.get('closest_location');
			$.post(coreData.ajaxurl, {
				action: 'findClosestLocation',
				location: location,
				userCoords: userCoords
			}, function(response) {
				Cookies.set('closest_location', response);
				// No we need to reinitiate all the loadable elements
				response = JSON.parse(response);
				const locationID = response.id;
				let clientData = {};
				if (locationID) {
					clientData = coreData.clientData[locationID];
				}

				// Check to see if the current location is the same
				// as the new location.
				let updateLocation = true;
				if (typeof currentLocation !== "undefined") {
					const location = JSON.parse(currentLocation);
					if (location.id === locationID) {
						updateLocation = false;
					}
				}

				// only update the site if the location has changed
				if (updateLocation) {
					// Only get the templates that have the dynamic template class
					const templates = $(".sp-template[data-load='dynamic']");
					$.each(templates, function(key, template) {
						const templateSlug = $(template).data('template');
						let data = {};
						if ($(template).length) {
							if ($(template).hasClass('merge-tag')) {
								data.is_merge_tag = true;
							}
							$.post(coreData.ajaxurl, {
								action: 'loadTemplate',
								template: templateSlug,
								data: data
							}, function(data) {
								$(template).replaceWith(data);
							});
						}
					});
				}
				if (typeof callback !== 'undefined') {
					callback();
				}
			})
		});
	}
}

// Read a page's GET URL variables and return them as an associative array.
window.getUrlVars = function() {
	let vars = {};
	let hash;
	const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	for (let i = 0; i < hashes.length; i++) {
		hash = hashes[i].split('=');
		if (typeof hash[1] !== 'undefined') {
			vars[hash[0]] = hash[1];
		}
	}
	return vars;
}

window.getUrlParameter = function getUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');
		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
};

window.popupCenter = function(url, title, w, h) {
	const y = window.outerHeight / 2 + window.screenY - ( h / 2)
	const x = window.outerWidth / 2 + window.screenX - ( w / 2)
	return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
}

window.formPopup = function formPopup(data) {
	const form = typeof data.form !== "undefined" ? data.form : false;
	const title = typeof data.title !== "undefined" ? data.title : false;
	const content = typeof data.content !== "undefined" ? data.content : false;
	if (form) {
		$.magnificPopup.close();
		$('body').addClass('mfp-open');
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: 'form-popup fade-in',
			tLoading: coreData.loader,
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "formPopup",
						form: form,
						title: title,
						content: content
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			},
			callbacks: {
				ajaxContentAdded: function() {

				}
			}
		});
		$.magnificPopup.instance.close = function () {
			$('body').removeClass('mfp-open');
			$.magnificPopup.proto.close.call(this);
		}
	}
}

window.contactPopup = function formPopup(data) {
	const form = typeof data.form !== "undefined" ? data.form : false;
	const title = typeof data.title !== "undefined" ? data.title : false;
	const content = typeof data.content !== "undefined" ? data.content : false;
	if (form) {
		$.magnificPopup.close();
		$('body').addClass('mfp-open');
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: 'contact-popup fade-in',
			tLoading: coreData.loader,
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "contactPopup",
						form: form,
						title: title,
						content: content
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			},
			callbacks: {
				ajaxContentAdded: function() {
					const mapCarouselSlickPopup = $(".map-carousel-slick-popup").slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						initialSlide: 0,
						focusOnSelect: true,
						autoplay: true,
						autoPlaySpeed: 500,
						speed: 500,
						mobileFirst: true,
						adaptiveHeight: true,
						arrows: false,
						dots: true,
						infinite: true,
						fade: true,
						appendDots: '.map-carousel-dots',
					}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
						$('.slick-slide', $(this)).removeClass('slick-fix');
						$('.slick-active.slick-cloned', $(this)).addClass('slick-fix');
					});
				}
			}
		});
		$.magnificPopup.instance.close = function () {
			$('body').removeClass('mfp-open');
			$.magnificPopup.proto.close.call(this);
		}
	}
}

// $(document).ready(function() {
// 	const hash = window.location.hash;
// 	if ( hash == '' || hash == '#' || hash == undefined ) return false;
// 	let target = $(hash);
// 	target = target.length ? target : $('[name=' + hash.slice(1) +']');
// 	if (target.length) {
// 		$('html,body').stop().animate({
// 			scrollTop: target.offset().top - 175
// 		}, 'linear');
// 	}
// })
