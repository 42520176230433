/* Content Block Alt Block JS File */
$(document).ready(function() {
	if ($('.content-block-alt-wrapper').length > 0) {

	}
});


$(window).on('resize rotate load', function() {
	if ($('.content-block-alt-wrapper').length > 0) {
		if ($(window).width() < 1500) {
			$('.content-block-alt-wrapper #col-content .section-content').appendTo(".content-block-alt-wrapper #col-title");
		} else {
			$('.content-block-alt-wrapper #col-title .section-content').appendTo(".content-block-alt-wrapper #col-content");
		}
	}
});
