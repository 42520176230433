/* Parent And Child Links Block JS File */
$(document).ready(function() {
	if ($('.parent-and-child-links-wrapper').length) {
		$('.parent-and-child-links-wrapper').on('mouseleave', function() {
			// console.log('out');
			$('.parent-and-child-links-wrapper .section-hover-links .section-left').removeClass('shrink');
			$('.parent-and-child-links-wrapper .section-hover-links .section-right').removeClass('shrink');
			$('.parent-and-child-links-wrapper .section-hover-links .section-left').removeClass('active');
			$('.parent-and-child-links-wrapper .section-hover-links .section-right').removeClass('active');
		});
		$('.parent-and-child-links-wrapper .section-hover-links .section-left').on('mouseenter', function() {
			// console.log('left');
			$('.parent-and-child-links-wrapper .section-hover-links .section-left').removeClass('shrink');
			$('.parent-and-child-links-wrapper .section-hover-links .section-right').removeClass('active');
			$('.parent-and-child-links-wrapper .section-hover-links .section-right').addClass('shrink');
			$('.parent-and-child-links-wrapper .section-hover-links .section-left').addClass('active');
		});
		$('.parent-and-child-links-wrapper .section-hover-links .section-right').on('mouseenter', function() {
			// console.log('right');
			$('.parent-and-child-links-wrapper .section-hover-links .section-right').removeClass('shrink');
			$('.parent-and-child-links-wrapper .section-hover-links .section-left').removeClass('active');
			$('.parent-and-child-links-wrapper .section-hover-links .section-left').addClass('shrink');
			$('.parent-and-child-links-wrapper .section-hover-links .section-right').addClass('active');
		});
		$(window).on('load resize rotate', function() {
			if ($(window).width() > 991) {
				$('.parent-and-child-links-wrapper .section-hover-links .section-parent .section-child .section-service-links').each(function() {
					const element = $(this);
					const height = element.height();
					element.css('margin-top', '-' + height + 'px');
				});
			} else {
				$('.parent-and-child-links-wrapper .section-hover-links .section-parent .section-child .section-service-links').css('margin-top', '0');
			}
		});
	}
});
