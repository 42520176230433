/* Banner Content Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';

$(document).ready(function() {
	if ($(".banner-content-wrapper").length) {

		$(".banner-content-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			fade: true,
			rows: 0
		});

		if ($(".banner-content-wrapper .banner-map").length) {
			const bannerMapController = new ScrollMagic.Controller();
			const bannerMapScene = new ScrollMagic.Scene({
				triggerHook: "onEnter",
				duration: "200%"
			})
			.setTween(".banner-content-wrapper .banner-map", {
				y: "40%"
			});
			bannerMapScene.addTo(bannerMapController);
		}

		if ($(".banner-content-wrapper .banner-background .background").length) {
			const bannerContentController = new ScrollMagic.Controller();
			const bannerContentScene = new ScrollMagic.Scene({
				triggerHook: "onEnter",
				duration: "200%"
			})
			.setTween(".banner-content-wrapper .banner-background .background", {
				y: "40%"
			});
			bannerContentScene.addTo(bannerContentController);
		}
	}
});

