/* Blogs Slider Block JS File */
import slick from 'slick-carousel';

$(document).ready(function() {
	const max = parseInt($('.blogs-slider-wrapper .section-blogs-slider #blogs-slider-arrows-count').attr('data-count'), 10);
	const blogs_max = parseInt($('.blogs-slider-wrapper .section-blogs-slider #blogs-slider-arrows-count').attr('data-blogs'), 10);
	$(".blogs-slider-wrapper .section-blogs-slider .blogs-slide-wrapper .blogs-slider-slick").on('init', function() {
		if ($(window).width() > 991) {
			$('.slick-slide[data-slick-index="' + 0 + '"]').addClass('slick-fix-hidden');
		}
	}).slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		focusOnSelect: true,
		autoplay: true,
		autoPlaySpeed: 1000,
		speed: 1000,
		fade: true,
		// fade: false,
		mobileFirst:true,
		adaptiveHeight: false,
		arrows: true,
		dots: false,
		rows: 0,
		appendArrows: '.blogs-slider-arrows',
		// appendArrows: '.section-blogs-navigation-arrows',
		prevArrow: '<span class="prev-arrow"></span><div class="pagination"> <span id="current">1</span> / <span id="max">'+ blogs_max +'</span>',
		nextArrow: '</div><span class="next-arrow"></span>',
		responsive: [
			{
				breakpoint: 991, // 991, 1200
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 1,
					arrows: true,
					appendArrows: '.section-blogs-navigation-arrows',
					fade: false,
				}
			},
			{
				breakpoint: 1499, // 1499, 992
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 1,
					fade: false,
					// fade: true,
					arrows: true,
					appendArrows: '.section-blogs-navigation-arrows',
					// appendArrows: '.blogs-slider-arrows',
				}
			}
		]
	}).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		if ((nextSlide + 1) > blogs_max) {
			$('.blogs-slider-wrapper #current').text((nextSlide + 1) - blogs_max);
		} else {
			$('.blogs-slider-wrapper #current').text(nextSlide + 1);
		}

		if ($(window).width() > 991) {
			$('.slick-slide', $(this)).removeClass('slick-fix');
			if ((currentSlide < nextSlide && (nextSlide != max || currentSlide != 0)) || (currentSlide > 1 && nextSlide == 0)) {
				$('.slick-slide[data-slick-index="' + currentSlide + '"]').addClass('slick-fix-hidden');
			}
			if ((currentSlide == 0) && nextSlide == max) {
				$('.slick-slide[data-slick-index="' + -1 + '"]').addClass('slick-fix');
			}
			else if ((currentSlide == max || currentSlide == (max - 1) ||
				currentSlide == (max - 2)) && nextSlide == 0) {
				$('.slick-slide[data-slick-index="' + (max + 1) + '"]').addClass('slick-fix');
			}
			else if ((currentSlide == max || currentSlide == (max - 1)) && nextSlide == 1) {
				$('.slick-slide[data-slick-index="' + (max + 2) + '"]').addClass('slick-fix');
			}
			else if ((currentSlide == max) && nextSlide == 2) {
				$('.slick-slide[data-slick-index="' + (max + 3) + '"]').addClass('slick-fix');
			}
		}
	}).on('afterChange', function(event, slick, currentSlide, nextSlide) {
		if ($(window).width() > 991) {
			$('.slick-slide', $(this)).removeClass('slick-fix-hidden');
			if ((currentSlide == 0)) {
				$('.slick-slide[data-slick-index="' + max + '"]').addClass('slick-fix-hidden');
				$('.slick-slide[data-slick-index="' + -1 + '"]').addClass('slick-fix-hidden');
			} else {
				$('.slick-slide[data-slick-index="' + (currentSlide - 1) + '"]').addClass('slick-fix-hidden');
			}
		}
	});

	// $(window).on('load resize rotate', function() {
	// 	if ($(window).width() > 991) {
	// 		$('.blogs-slide .box-body .section-box-body').each(function() {
	// 			const element = $(this);
	// 			const height = element.height();
	// 			element.css('margin-top', '-' + height + 'px');
	// 		});
	// 	} else {
	// 		$('.blogs-slide .box-body .section-box-body').css('margin-top', '0');
	// 	}
	// });
});
